<template>
    <div>
        <v-container v-if="ready">
            <v-card class="pa-4">

                <h1 class="mb-12">{{ $t('listaccount.title') }}</h1>

                <!-- {{ contatti }} -->
                <v-data-table dense item-key="nome" :headers="headers" :items="accounts" :items-per-page="50"
                    class="elevation-1">


                    <template v-slot:item.isAdmin="{ item }">
                        <v-btn :disabled="item.isLocked" small icon class="my-2" @click="changeIsAdmin(item)">

                            <v-icon :color="icon_status(item, 'admin')[0]">{{ icon_status(item, 'admin')[1] }}</v-icon>
                            <!-- <v-icon v-if="item.isAdmin" color="red">mdi-account-supervisor-circle</v-icon>
                            <v-icon v-if="!item.isAdmin" color="black">mdi-minus</v-icon> -->
                        </v-btn>
                    </template>
                    <!--  -->
                    <template v-slot:item.isContactManager="{ item }">
                        <v-btn :disabled="item.isLocked" small icon class="my-2" @click="changeIsContactManager(item)">
                            <v-icon :color="icon_status(item, 'contacts')[0]">{{ icon_status(item, 'contacts')[1] }}
                            </v-icon>
                            <!-- <v-icon v-if="item.isEditor || item.isAdmin" color="red">mdi-content-save-edit</v-icon> -->
                            <!-- <v-icon v-if="!item.isEditor && !item.isAdmin" color="black">mdi-minus</v-icon> -->
                        </v-btn>
                    </template>
                    <!--  -->
                    <template v-slot:item.isEditor="{ item }">
                        <v-btn :disabled="item.isLocked" small icon class="my-2" @click="changeIsEditor(item)">
                            <v-icon :color="icon_status(item, 'editor')[0]">{{ icon_status(item, 'editor')[1] }}
                            </v-icon>

                            <!-- <v-icon v-if="item.isEditor || item.isAdmin" color="red">mdi-content-save-edit</v-icon> -->
                            <!-- <v-icon v-if="!item.isEditor && !item.isAdmin" color="black">mdi-minus</v-icon> -->
                        </v-btn>
                    </template>
                    <!--  -->
                    <template v-slot:item.isLocked="{ item }">
                        <v-btn small icon class="my-2" @click="changeIsLocked(item)">
                            <v-icon :color="icon_status(item, 'locked')[0]">{{ icon_status(item, 'locked')[1] }}
                            </v-icon>
                            <!-- <v-icon v-if="item.isLocked" color="red">mdi-lock</v-icon> -->
                            <!-- <v-icon v-if="!item.isLocked" color="black">mdi-minus</v-icon> -->
                        </v-btn>
                    </template>


                </v-data-table>

                <v-dialog v-model="delete_dialog" max-width="400">
                    <v-card>
                        <v-card-title>
                            {{ $t('getcontacts.dialogdelete.title') }}
                        </v-card-title>
                        <v-card-text>
                            {{ $t('getcontacts.dialogdelete.message') }}
                        </v-card-text>
                        <v-card-actions>
                            <v-btn :disabled="deleting" outlined @click="delete_dialog = false">{{
                                    $t('getcontacts.dialogdelete.btn.close')
                            }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="deleting" outlined color="red" @click="delete_contact()">{{
                                    $t('getcontacts.dialogdelete.btn.confirm')
                            }}</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>

                <BackToAdminPanel />
            </v-card>
        </v-container>
    </div>
</template>

<script>
import BackToAdminPanel from '@/components/BackToAdminPanel.vue'

export default {
    props: {},
    components: {
        BackToAdminPanel
    },
    data: () => ({
        ready: false,
        id_to_delete: null,
        delete_dialog: false,
        deleting: false
        // contatti: []
    }),
    methods: {
        icon_status(account, role) {


            if (role == 'locked' && account.isLocked) return ['red', 'mdi-lock']
            if (role == 'locked' && !account.isLocked) return ['green', 'mdi-lock-open']

            if (account.isLocked) return ['red', 'mdi-minus']

            if (role == 'editor' && (account.isAdmin || account.isEditor)) return ['green', 'mdi-check']
            if (role == 'contacts' && (account.isAdmin || account.isContactManager)) return ['green', 'mdi-check']

            if (role == 'admin' && account.isAdmin) return ['green', 'mdi-check']

            return ['red', 'mdi-close']


            // if (role == 'locked' && account.isLocked) return 'mdi-lock'
            // if (role == 'locked' && !account.isLocked) return 'mdi-lock-open'

            // if (account.isLocked) return 'mdi-minus'

            // if (role == 'editor' && (account.isAdmin || account.isEditor)) return 'mdi-content-save-edit'
            // if (role == 'contacts' && (account.isAdmin || account.isContactManager)) return 'mdi-contacts'

            // if (role == 'admin' && account.isAdmin) return 'mdi-account-supervisor-circle'

            // return 'mdi-minus'

        },
        get_accounts() {
            this.$store.dispatch("accounts/act_get_all_accounts").then(() => {
                this.ready = true
            })
        },
        set_contact_state(id, state) {
            let id_state = {}
            id_state.id = id
            id_state.state = state
            this.$store.dispatch("contacts/act_change_contact_state", id_state)
        },
        changeIsAdmin(account) {
            console.log(account.email);

            if (!this.isAdmin) {
                alert(this.$t('listaccount.error.notAdmin'))
            }
            if (this.myUsername != account.email) {
                {
                    let payload = account
                    payload.isAdmin = !account.isAdmin
                    this.$store.dispatch("accounts/act_post_account_changes", payload)
                }
            } else {
                alert(this.$t('listaccount.error.cantEditYourself'))
            }
        },
        changeIsContactManager(account) {
            if (!this.isAdmin) {
                alert(this.$t('listaccount.error.notAdmin'))
            }
            if (this.myUsername != account.email) {
                if (account.isAdmin) {
                    alert(this.$t('listaccount.error.adminIsAContactManager'))
                    return
                }
                let payload = account
                payload.isContactManager = !account.isContactManager
                this.$store.dispatch("accounts/act_post_account_changes", payload)
            } else {
                alert(this.$t('listaccount.error.cantEditYourself'))
            }
        },


        changeIsEditor(account) {
            console.log(account.email);

            if (!this.isAdmin) {
                alert(this.$t('listaccount.error.notAdmin'))
            }
            if (this.myUsername != account.email) {
                if (account.isAdmin) {
                    alert(this.$t('listaccount.error.adminIsAnEditor'))
                    return
                }

                let payload = account
                payload.isEditor = !account.isEditor
                this.$store.dispatch("accounts/act_post_account_changes", payload)
            } else {
                alert(this.$t('listaccount.error.cantEditYourself'))
            }
        },
        changeIsLocked(account) {
            console.log(account.email);

            if (!this.isAdmin) {
                alert(this.$t('listaccount.error.notAdmin'))
            }
            if (this.myUsername != account.email) {
                let payload = account
                payload.isLocked = !account.isLocked
                this.$store.dispatch("accounts/act_post_account_changes", payload)
            } else {
                alert(this.$t('listaccount.error.cantEditYourself'))
            }
        },

    },
    mounted() {
        this.get_accounts()
    },
    created() { },
    computed: {
        accounts() {
            return this.$store.getters["accounts/get_accounts"]
        },
        headers() {
            return [
                {
                    text: this.$t('listaccount.tableheader.email'),
                    align: 'start',
                    sortable: true,
                    value: 'email',
                },
                {
                    text: this.$t('listaccount.tableheader.isAdmin'),
                    align: 'center',
                    sortable: false,
                    value: 'isAdmin',
                },

                {
                    text: this.$t('listaccount.tableheader.isContactManager'),
                    align: 'center',
                    sortable: false,
                    value: 'isContactManager',
                },

                {
                    text: this.$t('listaccount.tableheader.isEditor'),
                    align: 'center',
                    sortable: false,
                    value: 'isEditor',
                },
                {
                    text: this.$t('listaccount.tableheader.isLocked'),
                    align: 'center',
                    sortable: false,
                    value: 'isLocked',
                },

            ]
        },

    },


    watch: {}

}
</script>

<style>

</style>